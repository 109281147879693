import {
	Box,
	Button,
	Card, CardContent, CardHeader,
	Checkbox,
	Container,
	CssBaseline,
	Grid,
	IconButton,
	Input,
	ListItemText,
	MenuItem,
	Select,
	TextField,
	Typography
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {useSnackbar} from "notistack";
import React, {FunctionComponent, useEffect, useState, } from 'react';
import {Redirect, RouteComponentProps} from "react-router";
// import { isValidPhoneNumber } from 'libphonenumber-js';

import {ArrowBack} from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import {connect, useDispatch} from 'react-redux';
import {getUser, updateUserPreferences} from '../../../../api/dashboard';
import {MASTER_PERMISSIONS, USER_ROLES, USER_TYPE_VALUES} from '../../../../utilities/constants';
import MiniDrawer from "../../../common/components/SideDrawer";
import {CustomUser, } from '../../../common/contracts/dashboard';
import {roles} from '../../../edumaticaAcademy/enums';
import {setUniversityType} from '../../../redux/actions/universityTypeAction';
import {RootState} from '../../../redux/store';
import useStyles from './styles';


interface Props extends RouteComponentProps {
	user: CustomUser
}

interface UserPreferences {
	meetingPreference: string;
	autoRecord: boolean;
	wapAccountSid: string;
	instituteType: string;
	wapAuthToken: string;
	wapFrom: string;
	universityPrefixCode: string;
	wapBox: string;
	attendanceType: string;
	onJobTrainingEnable: boolean;
	multipleParticularEnable: boolean;
	paymentReciptEnable: boolean;
	libraryOptionalFlag: boolean;
	AttendaceDefaultFlag: boolean;
	isAccessionNumberOptional: boolean;
	edumaticaStyleReciept: boolean;
	deleteAssignedFeeStructure: boolean;
	deletePaymentReceipt: boolean;
	CRMMailIds: string[];
	customContactNumbers: string[];
	isPdfDownloadable: boolean;
	isFeeCollection: boolean;
	isLibraryBookDelete: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const ViewCustomer: FunctionComponent<Props> = ({ user, history }) => {

	const { enqueueSnackbar } = useSnackbar();
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	const [userDetails, setUserDetails] = useState<any>({}); //crosscheck
	const [meetingPreference, setMeetingPreference] = useState('')
	const [isContentDownloadable, setIsContentDownloadable] = useState('')
	const [isRecordingDownloadable, setIsRecordingDownloadable] = useState('')
	// by me
	const [isPdfDownloadable, setIsPdfDownloadable] = useState<boolean>(false)
	const [isFeeCollection, setisFeeCollection] = useState<boolean>(false)
	const [isLibraryBookDelete, setIsLibraryBookDelete] = useState<boolean>(false)

	//
	const [autoRecord, setautoRecord] = useState('')
	const [contentDownloadRole, setContentDownloadRole] = useState<roles[]>([])
	const [recordingDownloadRole, setRecordingDownloadRole] = useState<roles[]>([])
	const [wapBox, setWapBox] = useState<string>('')
	const [wapChannelId, setWapChannelId] = useState<string>('')
	const [wapApiKey, setWapApiKey] = useState<string>('')
	const [wapApiSecret, setWapApiSecret] = useState<string>('')
	const [instituteType, setInstituteType] = useState<string>('')
	const [onJobTraningType, setOnJobTraning] = useState<boolean>(false)
	const [multipleParticular, setMultipleParticular] = useState<boolean>(false)
	const [paymentRecipt, setPaymentRecipt] = useState<boolean>(false)


	const [libraryOptionalFlag, setLibraryOptionalFlag] = useState<boolean>(false)
	const [AttendaceDefaultFlag, setAttendaceDefaultFlag] = useState<boolean>(false)
	const [isAccessionNumberOptional, setIsAccessionNumberOptional] = useState<boolean>(false)
	const [deleteAssignedFeeStructure, setDeleteAssignedFeeStructure] = useState<boolean>(false)
	const [deletePaymentReceipt, setDeletePaymentReceipt] = useState<boolean>(false)
	const [edumaticaStyleReciept, setEdumaticaStyleReciept] = useState<boolean>(false)

	const [wapAccountSid, setWapAccountSid] = useState<string>('')
	const [wapAuthToken, setWapAuthToken] = useState<string>('')
	const [wapFrom, setWapFrom] = useState<string>('')
	const [universityPrefixCode, setUniversityPrefixCode] = useState<string>('')
	const [emailId, setEmailId] = useState<string>('')
	const [emailIdArr, setEmailIdArr] = useState<string[]>([])
	const [customContactNumber, setCustomContactNumber] = useState<string>('')
	const [customContactNumberArr, setCustomContactNumberArr] = useState<string[]>([])



	const [attendanceType, setAttendanceType] = useState('')
	const styles = useStyles();
	const dispatch = useDispatch();

 
	console.log()

	useEffect(() => {
		getLoggedInUserData();
	}, []);

	const getLoggedInUserData = async () => {
		// setLoading(true);
		// try {
		// 	const adminUserData = await getAdminData();
		// 	dispatch(setLoginUserData(JSON.stringify(adminUserData)));
		// 	setLoading(false);
		// 	if (adminUserData.isItDefaultPassword) {
		// 		localStorage.setItem('isItDefaultPassword', JSON.stringify(adminUserData.isItDefaultPassword));
		// 		setRedirectTo('/set-password');
		// 	}
		// } catch (error) {
		// 	setLoading(false);
		// 	enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
		// }
		if (JSON.parse(localStorage.getItem('isItDefaultPassword') || '{}') == true) {
			setRedirectTo('/set-password')
		}
		var allowedPermissions: string[] = [];
		var loginUserData = JSON.parse(localStorage.getItem('loginUserData') || '{}');
		var loginUserType = '';
		if (loginUserData && loginUserData.adminRole && loginUserData.adminRole.permissions) {
			allowedPermissions = loginUserData.adminRole.permissions;
			loginUserType = loginUserData.adminRole.name;
		}
		var hasEligibility = loginUserType == USER_ROLES.super || allowedPermissions.includes(MASTER_PERMISSIONS.getUser);
		if (!hasEligibility) {
			enqueueSnackbar("You don't have access to this route", { variant: 'warning' });
			setRedirectTo('/dashboard');
		} else {
			getUserData();
		}
	}

	const getUserData = async () => {
		setLoading(true);

		var userType = USER_TYPE_VALUES.TUTOR;
		switch (user.userType) {
			case USER_TYPE_VALUES.TUTOR:
				userType = USER_TYPE_VALUES.TUTOR;
				break;
			case USER_TYPE_VALUES.ORG:
				userType = USER_TYPE_VALUES.ORG;
				break;
			case USER_TYPE_VALUES.STUDENT:
				userType = USER_TYPE_VALUES.STUDENT;
				break;
			case USER_TYPE_VALUES.PARENT:
				userType = USER_TYPE_VALUES.PARENT;
				break;
			default:
				userType = USER_TYPE_VALUES.TUTOR;
				break;
		}

		try {
			const data = await getUser(userType, user._id);
			console.log("datata", data, user)
			setUserDetails(data);
			setMeetingPreference(data.meetingPreference)
			setWapBox(data.wapBox)
			setWapChannelId(data.wapChannelId)
			setWapApiKey(data.wapApiKey)
			setWapApiSecret(data.wapApiSecret)
			setWapAccountSid(data.wapAccountSid)
			setInstituteType(data.instituteType ? data.instituteType : 'school')
			setOnJobTraning(data?.onJobTrainingEnable ? data?.onJobTrainingEnable : false)
			setMultipleParticular(data?.multipleParticularEnable ? data?.multipleParticularEnable : false)
			setPaymentRecipt(data?.paymentReciptEnable ? data?.paymentReciptEnable : false)
			setLibraryOptionalFlag(data?.libraryOptionalFlag ? data?.libraryOptionalFlag : false)
			setAttendaceDefaultFlag(data?.AttendaceDefaultFlag ? data?.AttendaceDefaultFlag : false)
			setIsPdfDownloadable(data?.isPdfDownloadable ? data?.isPdfDownloadable : false)
			setisFeeCollection(data?.isFeeCollection ? data?.isFeeCollection : false)
			setIsLibraryBookDelete(data?.isLibraryBookDelete ? data?.isLibraryBookDelete:false)
			setIsAccessionNumberOptional(data?.isAccessionNumberOptional ? data?.isAccessionNumberOptional : false)
			setEdumaticaStyleReciept(data?.edumaticaStyleReciept ? data?.edumaticaStyleReciept : false)
			setDeleteAssignedFeeStructure(data?.deleteAssignedFeeStructure ? data?.deleteAssignedFeeStructure : false)
			setDeletePaymentReceipt(data?.deletePaymentReceipt ? data?.deletePaymentReceipt : false)
			setWapAuthToken(data.wapAuthToken)
			setWapFrom(data.wapFrom)
			setUniversityPrefixCode(data?.universityPrefixCode)
			setautoRecord(data.autoRecord ? 'True' : 'False')
			setIsContentDownloadable(data.isContentDownloadable ? 'True' : 'False')
			setIsRecordingDownloadable(data.isRecordingDownloadable ? 'True' : 'False')
			setContentDownloadRole(data.package.contentDownloadRole)
			setRecordingDownloadRole(data.package.recordingDownloadRole)
			setEmailIdArr(data?.CRMMailIds)
			setCustomContactNumberArr(data?.customContactNumbers)
			setLoading(false);
			setAttendanceType(data.attendanceType ? data.attendanceType : 'CourseWise')
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
			if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
				setRedirectTo('/login');
			}
		}
	}

	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const saveDetails = async () => {
		setLoading(true);
		if (user && user.ownerId) {
			try {
				const data = await updateUserPreferences({
					userType: user.userType,
					userId: user._id,
					purchasedId: userDetails.package._id,
					preferences: {
						userPreferences: {
							meetingPreference,
							autoRecord: autoRecord === 'True' ? true : false,
							wapAccountSid: wapAccountSid,
							instituteType: instituteType,
							wapAuthToken: wapAuthToken,
							wapFrom: wapFrom,
							universityPrefixCode: universityPrefixCode,
							wapBox: wapBox,
							wapChannelId: wapChannelId,
							wapApiKey: wapApiKey,
							wapApiSecret: wapApiSecret,
							attendanceType: attendanceType,
							onJobTrainingEnable: onJobTraningType,
							multipleParticularEnable: multipleParticular,
							paymentReciptEnable: paymentRecipt,
							libraryOptionalFlag: libraryOptionalFlag,
							AttendaceDefaultFlag: AttendaceDefaultFlag,
							isAccessionNumberOptional: isAccessionNumberOptional,
							edumaticaStyleReciept: edumaticaStyleReciept,
							deleteAssignedFeeStructure: deleteAssignedFeeStructure,
							deletePaymentReceipt: deletePaymentReceipt,
							CRMMailIds: emailIdArr,
							customContactNumbers: customContactNumberArr,
							isPdfDownloadable: isPdfDownloadable,
							isFeeCollection: isFeeCollection,
							isLibraryBookDelete:isLibraryBookDelete,
						},
						packagePreferences: {
							isContentDownloadable: isContentDownloadable === 'True' ? 1 : 0,
							isRecordingDownloadable: isRecordingDownloadable === 'True' ? 1 : 0,
							contentDownloadRole: contentDownloadRole,
							recordingDownloadRole: recordingDownloadRole
						}
					}
				});
				dispatch(setUniversityType({ userType: instituteType })); // Example: Setting the user type to 'Admin'
				setLoading(false);
				enqueueSnackbar('Updated successfully', { variant: 'success' });
				history.goBack()
			} catch (error) {
				setLoading(false);
				enqueueSnackbar(error.response?.data.message, { variant: 'warning' });
				if ((error.response?.status === 401) && (error.response?.data.message !== "TokenExpiredError")) {
					setRedirectTo('/login');
				}
			}
		} else {
			enqueueSnackbar('Something went wrong', { variant: 'warning' });
		}
	}
	const handleAddEmailArr = () => {
		const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (emailId === '') {
			return enqueueSnackbar('Email Cannot Be Empty', {
				variant: 'warning'
			});
		}
		if (!EMAIL_PATTERN.test(emailId)) {
			return enqueueSnackbar('Please enter Valid Email', {
				variant: 'warning'
			});
		}
		if (emailIdArr.includes(emailId)) {
			return enqueueSnackbar(
				`${emailId} already exists `,
				{
					variant: 'warning'
				}
			);
		}
		setEmailIdArr([...emailIdArr, emailId.toLocaleLowerCase()]);
		setEmailId('');
	};
	const handleAddContactsArr = () => {
		const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (customContactNumber === '') {
			return enqueueSnackbar('Contact Number Cannot Be Empty', {
				variant: 'warning'
			});
		}
		// if (!isValidPhoneNumber(customContactNumber)) {
		//  return	enqueueSnackbar('Please Enter a valid Contact Number', {
		// 	  variant: 'warning'
		// 	});
		// }

		if (customContactNumberArr.includes(customContactNumber)) {
			return enqueueSnackbar(
				`${customContactNumber} already exists `,
				{
					variant: 'warning'
				}
			);
		}
		setCustomContactNumber('')
		setCustomContactNumberArr([...customContactNumberArr, customContactNumber])

	};
	const handleDeleteContactArr = (index: number) => {
		let tempArr = [...customContactNumberArr];
		tempArr.splice(index, 1);
		setCustomContactNumberArr(tempArr);
	};
	const handleDeleteSectionNameArr = (index: number) => {
		let tempArr = [...emailIdArr];
		tempArr.splice(index, 1);
		setEmailIdArr(tempArr);
	};
	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setContentDownloadRole(event.target.value as roles[]);
	};

	const handleChange2 = (event: React.ChangeEvent<{ value: unknown }>) => {
		setRecordingDownloadRole(event.target.value as roles[]);
	};

	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Customer Management</Typography>
							</Grid> */}
							<Grid item className={styles.header}>
								<IconButton onClick={() => history.goBack()}>
									<ArrowBack />
								</IconButton>
								<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
							</Grid>
							<Card className={styles.card} variant='outlined'>
								<CardHeader title='View Customer' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>

									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Customer Name</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													value={user.name ? user.name : ''}
													disabled
												/>
											</FormControl>
										</Grid>
									</Grid>


									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Email</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													value={user.emailId ? user.emailId : ''}
													disabled
												/>
											</FormControl>
										</Grid>
									</Grid>

									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Mobile</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													value={user.mobileNo ? user.mobileNo : ''}
													disabled
												/>
											</FormControl>
										</Grid>
									</Grid>

									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Package</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													value={userDetails && userDetails.package ? userDetails.package.name : ''}
													disabled
												/>
											</FormControl>
										</Grid>
									</Grid>

									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Address</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													value={userDetails && userDetails.address ? userDetails.address : ''}
													multiline
													disabled
												/>
											</FormControl>
										</Grid>
									</Grid>

									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Status</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													value={userDetails && userDetails.roleStatus ? userDetails.roleStatus : ''}
													disabled
												/>
											</FormControl>
										</Grid>
									</Grid>

									{userDetails && userDetails.lookupId && <Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Lookup Link</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													value={`app.edumatica.io/${userDetails.lookupId}`}
													disabled
												/>
											</FormControl>
										</Grid>
									</Grid>}

									{userDetails && userDetails.meetingPreference &&
										(user.userType == USER_TYPE_VALUES.TUTOR || user.userType == USER_TYPE_VALUES.ORG) &&
										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Meeting preference</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={8}>
												<Select fullWidth
													onChange={(e) => {
														setMeetingPreference(e.target.value as string)
													}}
													// disabled={disabled}
													value={meetingPreference}>
													<MenuItem value="BBB">BBB</MenuItem>
													<MenuItem value="ZOOM">ZOOM</MenuItem>
												</Select>
											</Grid>
										</Grid>}

									{
										(user.userType == USER_TYPE_VALUES.TUTOR || user.userType == USER_TYPE_VALUES.ORG) &&
										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>PDF Downloadble (Library)</Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={8}>
												<Select fullWidth
													onChange={(e) => {
														setIsPdfDownloadable(e.target.value as boolean)
													}}
													// disabled={disabled}
													value={isPdfDownloadable}>
													<MenuItem value="true">True</MenuItem>
													<MenuItem value="false">False</MenuItem>
												</Select>
											</Grid>
										</Grid>
									}
									{
										(user.userType === USER_TYPE_VALUES.TUTOR || user.userType === USER_TYPE_VALUES.ORG) &&
										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>Fee Collection (APJ Abdul Kalam) </Box>
												</FormControl>
											</Grid>
											<Grid item xs={12} md={8}>
												<Select fullWidth
													onChange={(e) => {
														setisFeeCollection(e.target.value as boolean)
													}}
													// disabled={disabled}
													value={isFeeCollection}>
													<MenuItem value="true">True</MenuItem>
													<MenuItem value="false">False</MenuItem>
												</Select>
											</Grid>
										</Grid>
									}
		

									{
										(user.userType == USER_TYPE_VALUES.TUTOR || user.userType == USER_TYPE_VALUES.ORG) &&
										<>
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Content Downloadable</Box>
													</FormControl>
												</Grid>
												<Grid item xs={12} md={8}>
													<Select fullWidth
														onChange={(e) => {
															setIsContentDownloadable(e.target.value as string)
														}}
														// disabled={disabled}
														value={isContentDownloadable}>
														<MenuItem value="True">True</MenuItem>
														<MenuItem value="False">False</MenuItem>
													</Select>
												</Grid>
											</Grid>

											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Content Downloadable Role</Box>
													</FormControl>
												</Grid>
												<Grid item xs={12} md={8}>
													<Select
														fullWidth
														multiple
														value={contentDownloadRole}
														onChange={handleChange}
														input={<Input />}
														renderValue={(selected) => (selected as string[]).join(', ')}
														MenuProps={MenuProps}
													>
														{Object.values(roles)?.map((name) => (
															<MenuItem key={name} value={name}>
																<Checkbox checked={contentDownloadRole.indexOf(name) > -1} />
																<ListItemText primary={name} />
															</MenuItem>
														))}
													</Select>
												</Grid>
											</Grid>

											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Recording Downloadable</Box>
													</FormControl>
												</Grid>
												<Grid item xs={12} md={8}>
													<Select fullWidth
														onChange={(e) => {
															setIsRecordingDownloadable(e.target.value as string)
														}}
														// disabled={disabled}
														value={isRecordingDownloadable}>
														<MenuItem value="True">True</MenuItem>
														<MenuItem value="False">False</MenuItem>
													</Select>
												</Grid>
											</Grid>

											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Recording Downloadable Role</Box>
													</FormControl>
												</Grid>
												<Grid item xs={12} md={8}>
													<Select
														fullWidth
														multiple
														value={recordingDownloadRole}
														onChange={handleChange2}
														input={<Input />}
														renderValue={(selected) => (selected as string[]).join(', ')}
														MenuProps={MenuProps}
													>
														{Object.values(roles)?.map((name) => (
															<MenuItem key={name} value={name}>
																<Checkbox checked={recordingDownloadRole.indexOf(name) > -1} />
																<ListItemText primary={name} />
															</MenuItem>
														))}
													</Select>
												</Grid>
											</Grid>

											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Auto Recording</Box>
													</FormControl>
												</Grid>
												<Grid item xs={12} md={8}>
													<Select fullWidth
														onChange={(e) => {
															setautoRecord(e.target.value as string)
														}}
														// disabled={disabled}
														value={autoRecord}>
														<MenuItem value="True">True</MenuItem>
														<MenuItem value="False">False</MenuItem>
													</Select>
												</Grid>
											</Grid>

											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>WhatsApp Box</Box>
													</FormControl>
												</Grid>
												<Grid item xs={12} md={8}>
													<Select fullWidth
														onChange={(e) => {
															setWapBox(e.target.value as string)
														}}
														value={wapBox}
													>
														<MenuItem value="">Select WhatsApp Box</MenuItem>
														<MenuItem value="gallabox">Gallabox</MenuItem>
														<MenuItem value="twilio">Twilio</MenuItem>
													</Select>
												</Grid>
											</Grid>

											{(wapBox === 'twilio') &&
												<React.Fragment>
													<Grid container className={styles.paddClass}>
														<Grid item xs={12} md={4}>
															<FormControl fullWidth margin="normal">
																<Box className={styles.label}>WhatsApp accountSid</Box>
															</FormControl>
														</Grid>

														<Grid item xs={12} md={8}>
															<FormControl fullWidth margin="normal">
																<TextField value={wapAccountSid} style={{ width: '100%' }}
																	onChange={(e) => {
																		setWapAccountSid(e.target.value as string)
																	}} placeholder="Enter WhatsApp accountSid..."
																/>
															</FormControl>
														</Grid>
													</Grid>

													<Grid container className={styles.paddClass}>
														<Grid item xs={12} md={4}>
															<FormControl fullWidth margin="normal">
																<Box className={styles.label}>WhatsApp authToken</Box>
															</FormControl>
														</Grid>

														<Grid item xs={12} md={8}>
															<FormControl fullWidth margin="normal">
																<TextField value={wapAuthToken} style={{ width: '100%' }}
																	onChange={(e) => {
																		setWapAuthToken(e.target.value as string)
																	}} placeholder="Enter WhatsApp authToken..."
																/>
															</FormControl>
														</Grid>
													</Grid>

													<Grid container className={styles.paddClass}>
														<Grid item xs={12} md={4}>
															<FormControl fullWidth margin="normal">
																<Box className={styles.label}>WhatsApp from</Box>
															</FormControl>
														</Grid>

														<Grid item xs={12} md={8}>
															<FormControl fullWidth margin="normal">
																<TextField value={wapFrom} style={{ width: '100%' }}
																	onChange={(e) => {
																		setWapFrom(e.target.value as string)
																	}} placeholder="Enter WhatsApp from..."
																/>
															</FormControl>
														</Grid>
													</Grid>
												</React.Fragment>
											}

											{(wapBox === 'gallabox') &&
												<React.Fragment>
													<Grid container className={styles.paddClass}>
														<Grid item xs={12} md={4}>
															<FormControl fullWidth margin="normal">
																<Box className={styles.label}>WhatsApp ChannelId</Box>
															</FormControl>
														</Grid>

														<Grid item xs={12} md={8}>
															<FormControl fullWidth margin="normal">
																<TextField value={wapChannelId} style={{ width: '100%' }}
																	onChange={(e) => {
																		setWapChannelId(e.target.value as string)
																	}} placeholder="Enter WhatsApp ChannelId..."
																/>
															</FormControl>
														</Grid>
													</Grid>

													<Grid container className={styles.paddClass}>
														<Grid item xs={12} md={4}>
															<FormControl fullWidth margin="normal">
																<Box className={styles.label}>WhatsApp ApiKey</Box>
															</FormControl>
														</Grid>

														<Grid item xs={12} md={8}>
															<FormControl fullWidth margin="normal">
																<TextField value={wapApiKey} style={{ width: '100%' }}
																	onChange={(e) => {
																		setWapApiKey(e.target.value as string)
																	}} placeholder="Enter WhatsApp ApiKey..."
																/>
															</FormControl>
														</Grid>
													</Grid>

													<Grid container className={styles.paddClass}>
														<Grid item xs={12} md={4}>
															<FormControl fullWidth margin="normal">
																<Box className={styles.label}>WhatsApp ApiSecret</Box>
															</FormControl>
														</Grid>

														<Grid item xs={12} md={8}>
															<FormControl fullWidth margin="normal">
																<TextField value={wapApiSecret} style={{ width: '100%' }}
																	onChange={(e) => {
																		setWapApiSecret(e.target.value as string)
																	}} placeholder="Enter WhatsApp ApiSecret..."
																/>
															</FormControl>
														</Grid>
													</Grid>
												</React.Fragment>
											}


											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Institute Type</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth margin="normal">
														<Select
															value={instituteType}
															onChange={(
																e: React.ChangeEvent<{ value: unknown }>
															) => setInstituteType(e.target.value as string)}
														>
															<MenuItem value="">Select Institute Type</MenuItem>
															<MenuItem value="school">School</MenuItem>
															<MenuItem value="university">University</MenuItem>
															<MenuItem value="School Only">School Only</MenuItem>
														</Select>
													</FormControl>
												</Grid>
											</Grid>

											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Attendance Type</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth margin="normal">
														<Select
															value={attendanceType}
															onChange={(
																e: React.ChangeEvent<{ value: unknown }>
															) => setAttendanceType(e.target.value as string)}
														>
															<MenuItem value="">Select Attendance Type</MenuItem>
															<MenuItem value="CourseWise">Course Wise</MenuItem>
															<MenuItem value="FullDay">Full Day</MenuItem>
														</Select>
													</FormControl>
												</Grid>
											</Grid>

											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>On Job Traning Enabled</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth margin="normal">
														<Select
															value={onJobTraningType}
															onChange={(
																e: React.ChangeEvent<{ value: unknown }>
															) => setOnJobTraning(e.target.value == 'true' ? true : false)}
														>
															{/* <MenuItem value="">Select On Job Traning</MenuItem> */}
															<MenuItem value='false'>No</MenuItem>
															<MenuItem value="true">Yes</MenuItem>

														</Select>
													</FormControl>
												</Grid>
											</Grid>
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Multiple Fee Header</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth margin="normal">
														<Select
															value={multipleParticular}
															onChange={(
																e: React.ChangeEvent<{ value: unknown }>
															) => setMultipleParticular(e.target.value == 'true' ? true : false)}
														>
															{/* <MenuItem value="">Select On Job Traning</MenuItem> */}
															<MenuItem value='false'>No</MenuItem>
															<MenuItem value="true">Yes</MenuItem>

														</Select>
													</FormControl>
												</Grid>
											</Grid>
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Payment Receipt Mail</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth margin="normal">
														<Select
															value={paymentRecipt}
															onChange={(
																e: React.ChangeEvent<{ value: unknown }>
															) => setPaymentRecipt(e.target.value == 'true' ? true : false)}
														>
															{/* <MenuItem value="">Select On Job Traning</MenuItem> */}
															<MenuItem value='false'>No</MenuItem>
															<MenuItem value="true">Yes</MenuItem>

														</Select>
													</FormControl>
												</Grid>
											</Grid>
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Library Book (Bulk Delete)</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth margin="normal">
														<Select
															value={isLibraryBookDelete}
															onChange={(
																e: React.ChangeEvent<{ value: unknown }>
															) => setIsLibraryBookDelete(e.target.value == 'true' ? true : false)}
														>
															{/* <MenuItem value="">Select On Job Traning</MenuItem> */}
															<MenuItem value='false'>No</MenuItem>
															<MenuItem value="true">Yes</MenuItem>

														</Select>
													</FormControl>
												</Grid>
											</Grid>
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Library Optional Flag Enabled</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth margin="normal">
														<Select
															value={libraryOptionalFlag}
															onChange={(
																e: React.ChangeEvent<{ value: unknown }>
															) => setLibraryOptionalFlag(e.target.value == 'true' ? true : false)}
														>
															{/* <MenuItem value="">Select On Job Traning</MenuItem> */}
															<MenuItem value='false'>No</MenuItem>
															<MenuItem value="true">Yes</MenuItem>

														</Select>
													</FormControl>
												</Grid>
											</Grid>
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Attendance Default Notification</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth margin="normal">
														<Select
															value={AttendaceDefaultFlag}
															onChange={(
																e: React.ChangeEvent<{value: unknown}>
															) => setAttendaceDefaultFlag(e.target.value == 'true' ? true : false)}
														>
															{/* <MenuItem value="">Select On Job Traning</MenuItem> */}
															<MenuItem value='false'>No</MenuItem>
															<MenuItem value="true">Yes</MenuItem>

														</Select>
													</FormControl>
												</Grid>
											</Grid>
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Library Accession Number Optional</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth margin="normal">
														<Select
															value={isAccessionNumberOptional}
															onChange={(
																e: React.ChangeEvent<{ value: unknown }>
															) => setIsAccessionNumberOptional(e.target.value == 'true' ? true : false)}
														>
															{/* <MenuItem value="">Select On Job Traning</MenuItem> */}
															<MenuItem value='false'>No</MenuItem>
															<MenuItem value="true">Yes</MenuItem>

														</Select>
													</FormControl>
												</Grid>
											</Grid>
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Delete Assigned Fee Structure</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth margin="normal">
														<Select
															value={deleteAssignedFeeStructure}
															onChange={(
																e: React.ChangeEvent<{ value: unknown }>
															) => setDeleteAssignedFeeStructure(e.target.value == 'true' ? true : false)}
														>
															{/* <MenuItem value="">Select On Job Traning</MenuItem> */}
															<MenuItem value='false'>No</MenuItem>
															<MenuItem value="true">Yes</MenuItem>

														</Select>
													</FormControl>
												</Grid>
											</Grid>
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Delete Payment Receipt</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth margin="normal">
														<Select
															value={deletePaymentReceipt}
															onChange={(
																e: React.ChangeEvent<{ value: unknown }>
															) => setDeletePaymentReceipt(e.target.value == 'true' ? true : false)}
														>
															{/* <MenuItem value="">Select On Job Traning</MenuItem> */}
															<MenuItem value='false'>No</MenuItem>
															<MenuItem value="true">Yes</MenuItem>

														</Select>
													</FormControl>
												</Grid>
											</Grid>
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Edumatica Style Reciept</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth margin="normal">
														<Select
															value={edumaticaStyleReciept}
															onChange={(
																e: React.ChangeEvent<{ value: unknown }>
															) => setEdumaticaStyleReciept(e.target.value == 'true' ? true : false)}
														>
															{/* <MenuItem value="">Select On Job Traning</MenuItem> */}
															<MenuItem value='false'>No</MenuItem>
															<MenuItem value="true">Yes</MenuItem>

														</Select>
													</FormControl>
												</Grid>
											</Grid>
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>University Prefix Code</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth margin="normal">
														<TextField value={universityPrefixCode} style={{ width: '100%' }}
															onChange={(e) => {
																setUniversityPrefixCode(e.target.value as string)
															}} placeholder="Enter University Prefix Code..."
														/>
													</FormControl>
												</Grid>
											</Grid>
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>CRM Data Receive Email ID</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth>
														<TextField
															fullWidth
															placeholder="Enter Email Id"
															value={emailId}
															onChange={(
																e: React.ChangeEvent<{ value: unknown }>
															) => setEmailId(e.target.value as string)}
															InputProps={{
																endAdornment: (
																	<AddCircleIcon
																		onClick={() => handleAddEmailArr()}
																		style={{
																			color: '#4C8BF5',
																			width: '2rem',
																			height: '2rem',
																			cursor: 'pointer'
																		}}
																	/>
																)
															}}
														/>
													</FormControl>
												</Grid>
											</Grid>
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}></Grid>
												<Grid item xs={12} md={8} sm={4}>

													<Grid item xs={12} md={8}
														sm={4}
														style={{
															display: 'flex',
															gap: '1rem',
															alignItems: 'center',
															flexWrap: 'wrap',


														}}
													>
														{emailIdArr?.map((el, index) => (
															<Box
																key={index}
																style={{
																	background: 'rgba(46, 127, 248, 0.14)',
																	borderRadius: '5rem',
																	padding: '7px 15px 7px 19px',
																	display: 'flex',
																	alignItems: 'center'
																}}
															>
																<Typography
																	style={{
																		textAlign: 'center',
																		fontSize: '1rem',
																		fontWeight: 400,
																		marginRight: '1rem'
																	}}
																>
																	{el}
																</Typography>
																<DeleteIcon
																	onClick={() => handleDeleteSectionNameArr(index)}
																	style={{
																		color: '#C4C4C4',
																		width: '1.3rem',
																		height: '1.3rem',
																		cursor: 'pointer'
																	}}
																></DeleteIcon>
															</Box>
														))}
													</Grid>
												</Grid>
											</Grid>
											<Grid container className={styles.paddClass} style={{ marginTop: '1%' }}>
												<Grid item xs={12} md={4}>
													<FormControl fullWidth margin="normal">
														<Box className={styles.label}>Custom Contact Numbers</Box>
													</FormControl>
												</Grid>

												<Grid item xs={12} md={8}>
													<FormControl fullWidth>
														<TextField
															fullWidth
															placeholder="Enter Custom Contact Number"
															value={customContactNumber}
															type='Number'
															onChange={(
																e: React.ChangeEvent<{ value: unknown }>
															) => setCustomContactNumber(e.target.value as string)}
															InputProps={{
																endAdornment: (
																	<AddCircleIcon
																		onClick={() => handleAddContactsArr()}
																		style={{
																			color: '#4C8BF5',
																			width: '2rem',
																			height: '2rem',
																			cursor: 'pointer'
																		}}
																	/>
																)
															}}
														/>
													</FormControl>
												</Grid>
											</Grid>
											<Grid container className={styles.paddClass}>
												<Grid item xs={12} md={4}></Grid>
												<Grid item xs={12} md={8} sm={4}>

													<Grid item xs={12} md={8}
														sm={4}
														style={{
															display: 'flex',
															gap: '1rem',
															alignItems: 'center',
															flexWrap: 'wrap',


														}}
													>
														{customContactNumberArr?.map((el, index) => (
															<Box
																key={index}
																style={{
																	background: 'rgba(46, 127, 248, 0.14)',
																	borderRadius: '5rem',
																	padding: '7px 15px 7px 19px',
																	display: 'flex',
																	alignItems: 'center'
																}}
															>
																<Typography
																	style={{
																		textAlign: 'center',
																		fontSize: '1rem',
																		fontWeight: 400,
																		marginRight: '1rem'
																	}}
																>
																	{el}
																</Typography>
																<DeleteIcon
																	onClick={() => handleDeleteContactArr(index)}
																	style={{
																		color: '#C4C4C4',
																		width: '1.3rem',
																		height: '1.3rem',
																		cursor: 'pointer'
																	}}
																></DeleteIcon>
															</Box>
														))}
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<span style={{ float: 'right', paddingRight: '7%', paddingTop: '25px' }} >
													<Button style={{ marginLeft: '15px' }} color="primary" disableElevation variant="contained" onClick={saveDetails}>
														Save
													</Button>
												</span>
											</Grid>
										</>}

								</CardContent>
							</Card>

						</Grid>
					</Container>
				</div >
			</MiniDrawer >
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	user: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(ViewCustomer);